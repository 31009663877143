import * as types from './types';

/**
 * Creates a function to filter old or used events.
 * @param {string} id an event ID to be removed from the list
 * @returns {(event) => boolean} a function that tests if a given event is valid
 * (not too old) and has an ID other than the ID provided.
 */
const validItemsWithoutId = (id) => (event) => event.isValid && event.id !== id;

const uiEvents = (state = [], action) => {
  switch (action.type) {
    case types.UIEVENTS_PUSH: {
      const { event } = action.payload;
      return [...state, event];
    }

    case types.UIEVENTS_REMOVE: {
      const { id } = action.payload;
      return state.filter(validItemsWithoutId(id));
    }

    default:
      return state;
  }
};

export default uiEvents;
