// From https://blog.webdevsimplified.com/2020-10/react-debounce/
import { useEffect, useCallback } from 'react';

/**
 * Custom hook for de-bouncing use effects.
 * @param {func} effect
 * @param {array} deps
 * @param {int} delay
 */
function useDebounceEffect(effect, deps, delay = 250) {
  // Use callback here is only used to create a debounced callBack
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}

export default useDebounceEffect;
