const QUOTE_TABS = {
  quote: 'quote',
  savedQuote: 'savedQuote',
  quoteRequests: 'quoteRequests',
  quoteRequestForm: 'quoteRequestForm',
  quoteRequestFormSuccess: 'quoteRequestFormSuccess',
};

const QUOTE_REQUEST_STATUS = {
  preparing: 'preparing',
  completed: 'completed',
  unquoted: 'unquoted',
};

const QUOTE_REQUEST_LIST_COLUMN_WIDTHS = {
  requestNumber: 12,
  transportType: 11,
  transportRoute: 15,
  cargoInformation: 40,
  requestStatus: 11,
  requestedAt: 8,
  requestedBy: 3,
};

const QUOTE_REQUEST_LIST_COLUMN_WIDTHS_MINI = {
  requestNumber: 29,
  transportType: 26,
  transportRoute: 45,
};

/**
 *
 * @param {boolean} isDetailsOpen
 * @param {i18n} isDetailsOpen
 * @returns {array} quoteRequestList column header
 */
const QUOTE_REQUEST_LIST_COLUMN_HEADER = (isDetailsOpen, t) => {
  const columnWidth = isDetailsOpen
    ? QUOTE_REQUEST_LIST_COLUMN_WIDTHS_MINI
    : QUOTE_REQUEST_LIST_COLUMN_WIDTHS;

  const fullColumns = [
    {
      label: t('quote:quoteRequestList.columns.requestNumber'),
      width: columnWidth.requestNumber,
    },
    {
      label: t('quote:quoteRequestList.columns.transportConditions'),
      width: columnWidth.transportType + columnWidth.transportRoute,
    },
    {
      label: t('quote:quoteRequestList.columns.cargoInformation'),
      width: columnWidth.cargoInformation,
    },
    {
      label: t('quote:quoteRequestList.columns.status'),
      width: columnWidth.requestStatus,
    },
    {
      label: t('quote:quoteRequestList.columns.requestedAt'),
      width: columnWidth.requestedAt + columnWidth.requestedBy,
    },
  ];

  if (isDetailsOpen) {
    return fullColumns.slice(0, 2);
  }

  return fullColumns;
};

const TRADE_DIRECTIONS = ['export', 'import'];
const TRANSPORTATION_MODES = ['OCEAN', 'AIR'];
const INCOTERMS = {
  import: ['EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CPT', 'CIF', 'CIP', 'DAT', 'DPU'],
  export: ['FCA', 'FAS', 'FOB', 'CFR', 'CPT', 'CIF', 'CIP', 'DAT', 'DPU', 'DAP', 'DDP'],
};
const INSURANCE_HANDLING_OPTIONS = [
  'self_coverage',
  'shippio_covers',
  'consultation_required',
  'no_insurance',
];
const CUSTOMS_HANDLING_OPTIONS = [
  'real_time',
  'deferment',
  'consultation_required',
  'prepaid',
  'pay_on_behalf',
];

const CARGO_TYPES = ['FCL', 'LCL'];
const CONTAINER_TYPE_OPTIONS = ['_20ft', '_40ft', '_40ft_hc', '_20ft_reefer', '_40ft_reefer', 'special_request'];
const DRAYAGE_TYPE_OPTIONS = ['_20ft_2axles', '_40ft_2axles', '_20ft_3axles', '_40ft_3axles', 'special_request'];
const REEFER_CONTAINER_OPTIONS = ['motor_generator_chassis_required', 'motor_generator_chassis_not_required', 'unknown'];
const INSURANCE_HANDLING_CHECKED_VALUED = 'consultation_required';
const CUSTOMS_HANDLING_CHECKED_VALUED = 'real_time';

const QUOTE_REQUEST_TYPES = {
  new_quote_request: 'new_quote_request',
  expiration_date_extension_request: 'expiration_date_extension_request',
};

const DEFAULT_TRADE_DIRECTION = 'import';
const DEFAULT_AVAILABLE_INCOTERMS = INCOTERMS[DEFAULT_TRADE_DIRECTION];
const DEFAULT_INCOTERM = {
  import: 'FOB',
  export: 'CIF',
};

const INITIAL_QUOTE_REQUEST_FORM_STATE = {
  tradeDirection: DEFAULT_TRADE_DIRECTION,
  transportationMode: TRANSPORTATION_MODES[0],
  incoterm: DEFAULT_INCOTERM[DEFAULT_TRADE_DIRECTION],
  availableIncoterms: DEFAULT_AVAILABLE_INCOTERMS,
  drayageTruckingRequested: true,
  podId: null,
  polId: null,
  cargoType: CARGO_TYPES[0],
  // not exactly the BE object, missing the drayage request
  requestDetails: {
    insuranceHandling: null,
    customsHandling: CUSTOMS_HANDLING_CHECKED_VALUED,
    remarks: null,
    cargoTitle: null,
    containerTypes: [],
    reeferContainerInfo: null,
    drayageTypes: [],
    containsDangerousGoods: false,
    isLongCargo: false,
    isNonStackableCargo: false,
    specialCargoDetails: null,
    specialTruckingRequested: false,
    specialTruckRequestDetails: null,
    collectionPlaces: [null],
    deliveryPlaces: [null],
  },
  disabled: {
    tradeDirection: false,
    transportationMode: false,
    incoterm: false,
    drayageTruckingRequested: false,
    cargoType: false,
    cargoTitle: false,
    containsDangerousGoods: false,
    isLongCargo: false,
    isNonStackableCargo: false,
    specialCargoDetails: false,
    specialTruckingRequested: false,
    specialTruckRequestDetails: false,
    insuranceHandling: false,
    customsHandling: false,
    remarks: false,
    podId: false,
    polId: false,
    collectionPlaces: false,
    deliveryPlaces: false,
    containerTypes: false,
    drayageTypes: false,
    reeferContainerInfo: false,
  },
  // For Ports since disabled != required
  required: {
    podId: false,
    polId: false,
    deliveryPlaces: true,
  },
  additionalRequest: null,
};

const ADDITIONAL_QUOTE_REQUEST_TYPES = {
  POL: 'POL',
  POD: 'POD',
  collectionPlace: 'collectionPlace',
  deliveryPlace: 'deliveryPlace',
  drayageType: 'drayageType',
};

const INSURANCE_DISABLED_CONDITIONS = {
  import: ['CIF', 'CIP'],
  export: ['FCA', 'FAS', 'FOB', 'CFR', 'CPT'],
};

const INTERNAL_MODE_ROUTE_TYPES = {
  shipmentRequest: 'shipment-request',
  quoteStaff: 'quote-staff',
};
const ACTIVE_TAB_LOCAL_STORAGE_KEY = 'quote-active-tab';

const SAVED_QUOTE_PAGINATION_PER_PAGE = 50;

const SAVED_QUOTE_LIST_WIDTHS = {
  TransportConditions: 15,
  Route: 21,
  SavedQuoteName: 25,
  PriceAndExpirationDate: 30,
  Miscellaneous: 9,
};

export {
  INITIAL_QUOTE_REQUEST_FORM_STATE,
  QUOTE_REQUEST_LIST_COLUMN_HEADER,
  QUOTE_REQUEST_LIST_COLUMN_WIDTHS,
  QUOTE_REQUEST_LIST_COLUMN_WIDTHS_MINI,
  QUOTE_REQUEST_STATUS,
  QUOTE_TABS,
  TRADE_DIRECTIONS,
  TRANSPORTATION_MODES,
  INCOTERMS,
  DEFAULT_INCOTERM,
  INSURANCE_HANDLING_CHECKED_VALUED,
  CUSTOMS_HANDLING_CHECKED_VALUED,
  CARGO_TYPES,
  QUOTE_REQUEST_TYPES,
  INSURANCE_HANDLING_OPTIONS,
  CUSTOMS_HANDLING_OPTIONS,
  CONTAINER_TYPE_OPTIONS,
  REEFER_CONTAINER_OPTIONS,
  DRAYAGE_TYPE_OPTIONS,
  ADDITIONAL_QUOTE_REQUEST_TYPES,
  INSURANCE_DISABLED_CONDITIONS,
  INTERNAL_MODE_ROUTE_TYPES,
  ACTIVE_TAB_LOCAL_STORAGE_KEY,
  SAVED_QUOTE_PAGINATION_PER_PAGE,
  SAVED_QUOTE_LIST_WIDTHS,
};
