import * as types from './types';

// Initial state can be set here, although can also be fed in through createStore
const initial = {
  isChatOpen: false,
  isChatListOpen: false,
};

const chat = (state = initial, action) => {
  switch (action.type) {
    case types.CHAT_OPEN_EDIT: {
      const { isChatOpen } = action.payload;
      return {
        ...state,
        isChatOpen,
      };
    }

    case types.CHAT_LIST_OPEN_EDIT: {
      const { isChatListOpen } = action.payload;
      return {
        ...state,
        isChatListOpen,
      };
    }

    default:
      return state;
  }
};

export default chat;
