const TRADE_DIRECTION_EDIT = 'app/shipments/editTradeDirection';
const SHIPMENT_SORT_EDIT = 'app/shipments/editSelectedSortOption';
const SHIPMENT_SEARCH_EDIT = 'app/shipments/editSearchShipment';
const SHIPMENT_VIEW_EDIT = 'app/shipments/editShipmentListView';
const SHIPMENT_FILTER_EDIT = 'app/shipments/editShipmentFilterOption';
const SHIPMENT_SIDEBAR_PREVIOUS_EDIT = 'app/shipments/editPreviousSidebarState';

export {
  TRADE_DIRECTION_EDIT,
  SHIPMENT_SORT_EDIT,
  SHIPMENT_SEARCH_EDIT,
  SHIPMENT_VIEW_EDIT,
  SHIPMENT_FILTER_EDIT,
  SHIPMENT_SIDEBAR_PREVIOUS_EDIT,
};
