import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { CLIENT_ID, DOMAIN } from 'shared/utils/constants/auth0';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = propTypes;

export default Auth0ProviderWithHistory;
