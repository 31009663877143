import { STORAGE_ASSIGNED_CHAT, CHAT_ASSIGNEE_SELECTIONS } from 'shared/utils/constants/chat';
import { getLocalStorageObject } from 'shared/utils/utils';

import * as types from './types';

// Initial state can be set here, although can also be fed in through createStore
const initialUser = {
  company: null,
  customerTeams: null, // Only exposed to FO
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  teamId: null,
  threeWayFunctionDisabled: null, // Only exposed to FOfW
  chatAssigneeSelection: null,
  unreadChannelCount: null,
  unreadAssignedChannelCount: null,
};

const getLocalChatAssignee = () => {
  const assigneeSelection = getLocalStorageObject(STORAGE_ASSIGNED_CHAT)?.assigneeSelection;
  if (CHAT_ASSIGNEE_SELECTIONS[assigneeSelection]) {
    return assigneeSelection;
  }
  return CHAT_ASSIGNEE_SELECTIONS.allChats;
};

const user = (state = initialUser, action) => {
  switch (action.type) {
    case types.TEAM_ID_EDIT: {
      const { id } = action.payload;
      return {
        ...state,
        teamId: id,
      };
    }

    case types.SET_CURRENT_USER: {
      const { currentUser } = action.payload;
      return {
        company: currentUser.company.name,
        customerTeams: currentUser.customerTeams, // Only exposed to FO
        email: currentUser.email,
        firstName: currentUser.firstName,
        id: currentUser.id,
        language: currentUser.language,
        lastName: currentUser.lastName,
        teamId: currentUser.selectedCustomerTeamId,
        threeWayFunctionDisabled: currentUser.threeWayFunctionDisabled, // Only exposed to FOfW
        chatAssigneeSelection: getLocalChatAssignee(), // not provided by BE query
        unreadChannelCount: currentUser.unreadChannelCount,
        unreadAssignedChannelCount: currentUser.unreadAssignedChannelCount,
      };
    }

    case types.NAME_EDIT: {
      const { lastName, firstName } = action.payload;
      return {
        ...state,
        firstName,
        lastName,
      };
    }

    case types.UNREAD_CHANNEL_COUNT_EDIT: {
      const { unreadChannelCount } = action.payload;
      return {
        ...state,
        unreadChannelCount,
      };
    }

    case types.UNREAD_ASSIGNED_CHANNEL_COUNT_EDIT: {
      const { unreadAssignedChannelCount } = action.payload;
      return {
        ...state,
        unreadAssignedChannelCount,
      };
    }

    case types.CHAT_ASSIGNEE_SELECTION_EDIT: {
      const { chatAssigneeSelection } = action.payload;
      return {
        ...state,
        chatAssigneeSelection,
      };
    }

    case types.LANGUAGE_EDIT: {
      const { language } = action.payload;
      return {
        ...state,
        language,
      };
    }

    default:
      return state;
  }
};

export default user;
