const LANGUAGE_OPTIONS = {
  ja: 'ja',
  en: 'en',
};

const USER_STATUS = {
  invited: 'invited',
  active: 'active',
  deleted: 'deleted',
};

export {
  LANGUAGE_OPTIONS,
  USER_STATUS,
};
