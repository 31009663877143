import { UIEVENTS_PUSH } from '../uiEvents/types';

import { getUserTypeFromCookie } from '../../utils/auth/cookies';
import { USER_TYPE } from '../../utils/constants/auth';

/**
 * Middleware to stop any UI events being pushed for Internal Mode.
 */
const allowUIEvents = () => (next) => (action) => {
  if (action?.type === UIEVENTS_PUSH) {
    // Prevent UI events from being pushed for Internal Mode
    if (getUserTypeFromCookie() === USER_TYPE.staff) {
      return null;
    }
  }

  return next(action);
};

export default allowUIEvents;
