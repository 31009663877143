import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

const propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
    }),
  )).isRequired,
};

const SidebarMenuPlaceholder = ({ menuItems }) => {
  const listItemsGenerator = (items) => items.map(({ title, icon }) => (
    <ListItem
      key={title}
      className="sidebar__nav-list__item">
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </ListItem>
  ));

  const listItemsAbove = listItemsGenerator(menuItems[0]);
  const listItemsBelow = listItemsGenerator(menuItems[1]);

  return (
    <>
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsAbove}
      </List>
      <Divider className="sidebar__divider" />
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsBelow}
      </List>
    </>
  );
};

SidebarMenuPlaceholder.propTypes = propTypes;

export default SidebarMenuPlaceholder;
