// Basic idea of utils file, definitely open to changing it's location or filename etc...

/**
 * Formatting the day string so we handle 'day' or 'days' correctly
 * @param {number} numberOfDays the number of days that the day format is for
 * @param {object} t the i18n object that takes care of translations
 */
const formatDayString = (numberOfDays, t) => t('translation:general.units.days', { s: numberOfDays === 1 ? '' : 's' });

/**
 * Formatting the a number values with respective commas e.g. "1,200"
 * @param {number} number the monetary number you want to format
 * @param {string} currency the currency which determines the locale, this is optional
 */
const formatCurrencyNumberToString = (number, currency = null) => {
  switch (currency) {
    case 'JPY':
      return number?.toLocaleString('ja-JP');

    case 'USD':
    // Divide number by 100 if USD
      return (number / 100)?.toLocaleString('en-US');

    default:
    // Uses the default locale detected
      return number?.toLocaleString();
  }
};

/**
 * Check if two arrays have the same values i.e. are equal. We can't use JS
 * strict equality because it compares the references as opposed to the values.
 *
 * Open to suggestions on how this could be more efficient. Perhaps a for loop
 * is more memory efficient as we don't create a set object in memory, thoughts?
 *
 * CAVEATS
 * - does not work for multi-dimensional arrays
 * - does not handle the case if you have duplicate elements within the array
 * @param {array} arrayA
 * @param {array} arrayB
 */
const checkArrayValueEquality = (arrayA, arrayB) => {
  // If array's aren't equal, don't bother to continue, their values are not equal
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  // Add both arrays as a new Set. This should remove duplicates between both
  // arrays, so should only be a set of unique values between the two arrays
  // e.g. ['a','b','c', 'y'] + ['c','a','b', 'x'] = ['a','b','c', 'x', 'y']
  const combined = new Set([...arrayA, ...arrayB]);
  // if the size of the set does not match the arrays, then there are non-matching values
  if (combined.size !== arrayA.length) {
    return false;
  }

  return true;
};

/**
 * TODO: DEPRECATED, replace instances of this with generateCustomerEntityName
 * Generate the full name of a customer user, for now it is taking the Japanese format
 * lastName -> firstName.
 *
 * TODO: add ability to differentiate with english names first name -> last name
 * @param {object} t the i18n object that takes care of translations
 * @param {object | null} customerUser the customer user object OR null if there is no-one
 * @param {bool} includeBrackets include brackets for no assignee
 */
const generateCustomerUserFullName = (t, customerUser = null, includeBrackets = true) => {
  if (customerUser?.firstName || customerUser?.lastName) {
    return `${customerUser?.lastName || ''} ${customerUser?.firstName || ''}`;
  }

  if (includeBrackets) {
    return `(${t('shipment:assignCustomerUser.noAssignee')})`;
  }

  return t('shipment:assignCustomerUser.noAssignee');
};

/**
 * @param {object} t the i18n object that takes care of translations
 * @param {object | null} customerEntity the customer entity object OR null if there is no-one
 */
const generateCustomerEntityName = (t, customerEntity = null, includeBrackets = true) => {
  if (customerEntity?.firstName || customerEntity?.lastName || customerEntity?.name) {
    return `${customerEntity?.lastName || customerEntity?.name || ''} ${customerEntity?.firstName || ''}`;
  }

  if (includeBrackets) {
    return `(${t('shipment:assignCustomerUser.noAssignee')})`;
  }

  return t('shipment:assignCustomerUser.noAssignee');
};

// Format unit for billItems and pricingItems
const formatUnit = (unit) => {
  if (unit
    && !unit.includes('{')
    && !unit.includes('}')) {
    return unit;
  }
  return '';
};

const formatDecimals = (value, decimals = 3) => parseFloat(Number(value).toFixed(decimals));

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/**
 * Check whether an object is empty or not
 * https://stackoverflow.com/a/32108184
 * @param {object} obj
 */
const isObjectIsEmpty = (obj) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

/**
 * Parse a multidimensional object i.e. remove properties depending
 * on the given parse condition AND flatten to one level if required
 *
 * NOTE: does not flatten arrays in the object!
 *
 * Based off this: https://stackoverflow.com/a/55251598
 * @param {object} obj
 * @param {func} parseFn pass a function that parses object property data
 * to check if it's worth adding to the flattened object
 * @param {bool} flatten flag if we want to flatten or not
 */
const parseObject = (obj, parseFn = () => true, flatten = false) => {
  const parsed = {};

  Object.keys(obj).forEach((key) => {
    const property = obj[key];

    if (parseFn && parseFn(property)) {
      // Recursively parse objects, BUT not arrays
      if (typeof property === 'object' && property.constructor !== Array) {
        if (flatten) {
          Object.assign(parsed, parseObject(property, parseFn, flatten));
        } else {
          parsed[key] = {};
          Object.assign(parsed[key], parseObject(property, parseFn, flatten));
        }
      } else {
        parsed[key] = property;
      }
    }
  });

  return parsed;
};

/**
 * From https://stackoverflow.com/questions/6229197/how-to-know-if-two-arrays-have-the-same-values
 * Compare two arrays composed of primitive types (integers, strings).
 * @param {array} array1
 * @param {array} array2
 * @returns {bool}
 */
const compareArraysOfPrimitives = (array1, array2) => {
  if (array1?.length >= 0 && array2?.length >= 0 && array1?.length === array2?.length) {
    // .concat() to not mutate arguments
    const arr1 = array1.concat().sort();
    const arr2 = array2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }
  return false;
};

/**
 * Generates an unique random id
 */
const generateRandomId = () => {
  const random = Math.random().toString(16).slice(2);
  const time = (new Date()).getTime();
  return `uid${time}${random}`;
};

export {
  checkArrayValueEquality,
  formatCurrencyNumberToString,
  formatDayString,
  formatDecimals,
  formatUnit,
  generateCustomerUserFullName,
  generateCustomerEntityName,
  getWindowDimensions,
  isObjectIsEmpty,
  parseObject,
  compareArraysOfPrimitives,
  generateRandomId,
};
