import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en/translation.json';
import translationJA from '../locales/ja/translation.json';

import authEN from '../locales/en/auth.json';
import authJA from '../locales/ja/auth.json';

import settingsEN from '../locales/en/settings.json';
import settingsJA from '../locales/ja/settings.json';

import shipmentEN from '../locales/en/shipment.json';
import shipmentJA from '../locales/ja/shipment.json';

// simulation translation cannot be removed as some translation is used in Shipment
import simulationEN from '../locales/en/simulation.json';
import simulationJA from '../locales/ja/simulation.json';

import chatEN from '../locales/en/chat.json';
import chatJA from '../locales/ja/chat.json';

import invoicesEN from '../locales/en/invoices.json';
import invoicesJA from '../locales/ja/invoices.json';

const resources = {
  en: {
    auth: authEN,
    chat: chatEN,
    invoices: invoicesEN,
    settings: settingsEN,
    shipment: shipmentEN,
    simulation: simulationEN,
    translation: translationEN,
  },
  ja: {
    auth: authJA,
    chat: chatJA,
    invoices: invoicesJA,
    settings: settingsJA,
    shipment: shipmentJA,
    simulation: simulationJA,
    translation: translationJA,
  },
};

const options = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator'],

  lookupLocalStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    fallbackLng: 'ja',
    returnEmptyString: false,
    parseMissingKeyHandler: (key) => `Translation for ${key} not found`,
    keySeparator: '.', // char to separate keys
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
