import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from '../../../shared/components/Sidebar/Sidebar';
import SidebarMenuContainer from './SidebarMenu/SidebarMenuContainer';
import SidebarMenuPlaceholderContainer from './SidebarMenu/SidebarMenuPlaceholderContainer';

const propTypes = {
  placeholder: PropTypes.bool,
};

const SidebarContainer = ({ placeholder }) => (
  <Sidebar>
    {!placeholder ? <SidebarMenuContainer /> : <SidebarMenuPlaceholderContainer />}
  </Sidebar>
);

SidebarContainer.propTypes = propTypes;
SidebarContainer.defaultProps = { placeholder: false };

export default SidebarContainer;
