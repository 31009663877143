import { useEffect } from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { useSelector, useDispatch } from 'react-redux';

import { editNetworkRequest } from '../redux/apollo/actions';

// https://github.com/molindo/react-apollo-network-status#usage
const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

/**
 * Component that measures certain stats surrounding the Apollo network requests
 * https://github.com/molindo/react-apollo-network-status
 */
const ApolloNetworkStatusIndicator = () => {
  const dispatch = useDispatch();
  const pendingQueries = useSelector((state) => state.apollo.query.pending);

  // Only apply the status for queries that is set to true
  // Therefore, if you would like to use this functionality, make sure the context of your
  // query is set with { useApolloNetworkStatus: true }.
  // https://github.com/molindo/react-apollo-network-status#advanced-usage
  const status = useApolloNetworkStatus({
    shouldHandleOperation: (operation) => operation.getContext().useApolloNetworkStatus === true,
  });

  useEffect(() => {
    if (status.numPendingQueries === 0 && pendingQueries) {
      dispatch(editNetworkRequest('query', 'pending', false));
    }

    if (status.numPendingQueries > 0 && !pendingQueries) {
      dispatch(editNetworkRequest('query', 'pending', true));
    }
  }, [status.numPendingQueries, pendingQueries, dispatch]);

  return null;
};

export {
  link,
  ApolloNetworkStatusIndicator,
};
