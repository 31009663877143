const TRADE_DIRECTIONS = ['export', 'import'];

const SCHEDULE_METHOD = {
  VESSEL: 'vessel',
  CALENDAR: 'calendar',
};

const SCHEDULE_CALENDAR_DATE_TYPES = {
  ETD: 'etd',
  ETA: 'eta',
  CUTOFFDATE: 'cutOffDate',
  DELIVERY_DATE: 'deliveryDate',
};

const SCHEDULE_CALENDAR_DATE_OPTIONS = {
  import: { eta: 'eta', deliveryDate: 'deliveryDate' },
  export: { etd: 'etd', eta: 'eta' },
};

const CONTAINER_TYPES = [
  '_20ft',
  '_40ft',
  '_20ft_flat',
  '_40ft_flat',
  '_20ft_open_top',
  '_40ft_open_top',
  '_20ft_reefer',
  '_40ft_reefer',
  '_40ft_hc',
];

const DRAYAGE_TYPES = [
  '_20ft_2axles',
  '_40ft_2axles',
  '_20ft_3axles',
  '_40ft_3axles',
];

const TRUCK_TYPES = [
  'consolidated',
  'self_loader',
  'lowbed_semi',
  'high_flatbed',
  'any_2t',
  'powergate_2t',
  'refrigerated_2t',
  'crane_2t',
  'wing_2t',
  'flat_2t',
  'any_4t',
  'powergate_4t',
  'refrigerated_4t',
  'crane_4t',
  'wing_4t',
  'flat_4t',
  'any_10t',
  'powergate_10t',
  'refrigerated_10t',
  'crane_10t',
  'wing_10t',
  'flat_10t',
  'flat_trailer_20t',
  'wing_trailer_20t',
];

const CARGO_TYPES = {
  fcl: 'FCL',
  lcl: 'LCL',
};

const CUSTOMS_HANDLING = {
  real_time: 'real_time',
  deferment: 'deferment',
  consultation_required: 'consultation_required',
  prepaid: 'prepaid',
  pay_on_behalf: 'pay_on_behalf',
};

const INSURANCE_HANDLING = {
  self_coverage: 'self_coverage',
  shippio_covers: 'shippio_covers',
  consultation_required: 'consultation_required',
  no_insurance: 'no_insurance',
};

const INCOTERMS = {
  import: ['EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CPT', 'CIF', 'CIP', 'DAT', 'DPU'],
  export: ['FCA', 'FAS', 'FOB', 'CFR', 'CPT', 'CIF', 'CIP', 'DAT', 'DPU', 'DAP', 'DDP'],
};

const SIMULATION_MODES = {
  quote: 'quote',
  shipmentRequest: 'shipment-request',
  confirmShipment: 'confirm-shipment',
};

const TRANSPORTATION_MODES = {
  AIR: 'AIR',
  OCEAN: 'OCEAN',
};

// From PM
// In Export, Dxx Incoterms should always show both LT Domestic and Overseas

const PLACES_DISPLAY_RULES = {
  export: {
    domestic: [
      'FCA',
      'FOB',
      'FAS',
      'CIF',
      'CFR',
      'CPT',
      'CIP',
      'DAP',
      'DPU',
      'DDP',
      'DAT',
    ],
    overseas: [
      'DPU',
      'DDP',
      'DAP',
      'DAT',
    ],
  },
  import: {
    domestic: [
      'EXW',
      'FCA',
      'FOB',
      'FAS',
      'CIF',
      'CFR',
      'CPT',
      'CIP',
    ],
    overseas: [
      'EXW',
    ],
  },
};

// Missing values that we are required to throw an error
const MISSING_VALUES = {
  incoterms: null,
  POD: null,
  POL: null,
};

// Local Storage Keys
const STORAGE_TRADE_DIRECTION = 'quote-simulation-trade-direction';
const STORAGE_TRANSPORTATION_MODE = 'quote-simulation-transportation-mode';
const STORAGE_INCOTERMS = 'quote-simulation-incoterms';
const STORAGE_ROUTE = 'quote-simulation-route';
const STORAGE_CARGO_TYPE = 'quote-simulation-cargo-type';
const STORAGE_ASSIGNEE = 'quote-simulation-assignee';

const STORAGE_DEFAULTS = {
  STORAGE_TRADE_DIRECTION: 'import',
  STORAGE_CARGO_TYPE: CARGO_TYPES.fcl,
  STORAGE_TRANSPORTATION_MODE: {
    import: TRANSPORTATION_MODES.OCEAN,
    export: TRANSPORTATION_MODES.OCEAN,
  },
  STORAGE_INCOTERMS: { import: null, export: null },
  STORAGE_ROUTE: {
    import: { POL: null, POD: null },
    export: { POL: null, POD: null },
  },
};

const WALK_THROUGH_KEY = 'quote-chat-walkthrough';

const ANY_PORT = {
  id: 'any',
};

// In the future more conditions will be added - e.g. 'packaging',
// hence using a list of strings
const ADDITIONAL_REQUEST_DISABLED_CONDITIONS = {
  import: {
    CIF: ['insurance'],
    CIP: ['insurance'],
  },
  export: {
    FCA: ['insurance'],
    FAS: ['insurance'],
    FOB: ['insurance'],
    CFR: ['insurance'],
    CPT: ['insurance'],
  },
};

// Although this is located in simulations const file, this adjustment should
// be used app wide, and may be adjusted in the future
const TOTAL_CALCULATION_ERROR = 10e-10;

export {
  SCHEDULE_METHOD,
  SCHEDULE_CALENDAR_DATE_TYPES,
  SCHEDULE_CALENDAR_DATE_OPTIONS,
  CONTAINER_TYPES,
  CARGO_TYPES,
  CUSTOMS_HANDLING,
  INSURANCE_HANDLING,
  INCOTERMS,
  SIMULATION_MODES,
  TRANSPORTATION_MODES,
  DRAYAGE_TYPES,
  TRUCK_TYPES,
  TRADE_DIRECTIONS,
  PLACES_DISPLAY_RULES,
  MISSING_VALUES,
  STORAGE_ASSIGNEE,
  STORAGE_TRADE_DIRECTION,
  STORAGE_TRANSPORTATION_MODE,
  STORAGE_INCOTERMS,
  STORAGE_ROUTE,
  STORAGE_CARGO_TYPE,
  STORAGE_DEFAULTS,
  WALK_THROUGH_KEY,
  ANY_PORT,
  ADDITIONAL_REQUEST_DISABLED_CONDITIONS,
  TOTAL_CALCULATION_ERROR,
};
