import { createStore, compose } from 'redux';

// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
/* eslint-disable no-underscore-dangle */
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
  : compose;
/* eslint-enable */

/**
 * Generate the Redux store based on the given reducer and middleware.
 *
 * This is so we can have differing redux stores on the different apps with
 * different middleware applied.
 *
 * NOTE: Although the middleware is the same for both apps right now, it could easily
 * diverge, and as a precaution have set it up similar to the reducer where its passed in
 * @param {func} rootReducer
 * @param {func} middlewareEnhancer
 * @returns {object} Redux Store to pass in Redux Provider
 */
const generateStore = (rootReducer, middlewareEnhancer) => {
  const enhancer = middlewareEnhancer
    ? composeEnhancers(middlewareEnhancer)
    : composeEnhancers();

  return createStore(
    rootReducer, /* preloadedState, */
    enhancer,
  );
};

export default generateStore;
