const AUTH_TOKEN = `X-Auth-${process.env.REACT_APP_COOKIE_NAME_SUFFIX}`;
const AUTH_ROLE = `X-Role-${process.env.REACT_APP_COOKIE_NAME_SUFFIX}`;
const EXPIRATION_DAYS = 30;
const ROOT_PATH = '/';
// TODO: Keep this for a while to clear all created cookie
// In the future, we could consider to remove these paths
const ALL_PATHS = [
  '/',
  '/invoices',
  '/partners',
  '/partners/create',
  '/partners/edit',
  '/places',
  '/places/create',
  '/places/edit',
  '/products',
  '/products/create',
  '/products/edit',
  '/quote',
  '/quote/saved',
  '/quote/requests',
  '/quote/requests/create',
  '/shipment-request',
  '/confirm-shipment',
  '/shipments',
  '/shipment',
  '/products',
  '/partners',
  '/places',
  '/settings',
  '/settings/members',
  '/settings/user-groups',
];

/**
 * To return corresponding value of the given key stored in cookie
 * @param {string} parseKey
 * @returns {string | null}
 */
const parseCookie = (parseKey) => {
  let matchedValue = null;

  document.cookie.split(';').some((cookieItem) => {
    const [key, value] = cookieItem.split('=');

    if (key.trim() === parseKey) {
      matchedValue = value;
      return true; // returning true will break the loop
    }

    return false;
  });

  return matchedValue;
};

const getUserTokenFromCookie = () => parseCookie(AUTH_TOKEN);
const getUserTypeFromCookie = () => parseCookie(AUTH_ROLE);

/**
 * To reset auth information in cookie
 */
const removeCookieToken = () => {
  const domainCookie = `Domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
  const expirationCookie = 'Expires=Thu, 01 Jan 1970 00:00:01 GMT';

  const authTokenCookie = `${AUTH_TOKEN}=`;
  const userTypeCookie = `${AUTH_ROLE}=`;

  ALL_PATHS.forEach((path) => {
    const pathCookie = `Path=${path}`;
    document.cookie = `${authTokenCookie};${domainCookie};${expirationCookie};${pathCookie}`;
    document.cookie = `${userTypeCookie};${domainCookie};${expirationCookie};${pathCookie}`;
  });
};

/**
 * To set auth information in cookie (Token, Role, Domain, and Expires)
 * @param {string} authToken
 * @param {string} userType
 */
const storeCookieToken = (authToken, userType) => {
  removeCookieToken();

  const domainCookie = `Domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
  const pathCookie = `Path=${ROOT_PATH}`;

  const expiration = new Date();
  expiration.setDate(new Date().getDate() + EXPIRATION_DAYS);
  const expirationCookie = `Expires=${expiration.toUTCString()}`;

  const authTokenCookie = `${AUTH_TOKEN}=${authToken}`;
  const userTypeCookie = `${AUTH_ROLE}=${userType}`;

  document.cookie = `${authTokenCookie};${domainCookie};${expirationCookie};${pathCookie}`;
  document.cookie = `${userTypeCookie};${domainCookie};${expirationCookie};${pathCookie}`;
};

const extendCookieTokenExpiration = () => {
  const authToken = getUserTokenFromCookie();
  const userType = getUserTypeFromCookie();
  storeCookieToken(authToken, userType);
};

export {
  getUserTokenFromCookie,
  getUserTypeFromCookie,
  removeCookieToken,
  storeCookieToken,
  extendCookieTokenExpiration,
};
