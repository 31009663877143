import * as types from './types';

// Initial state can be set here, although can also be fed in through createStore
const initialApolloState = {
  query: { pending: false },
};

const apollo = (state = initialApolloState, action) => {
  switch (action.type) {
    case types.APOLLO_NETWORK_REQUESTS_EDIT: {
      const { requestType, status, value } = action.payload;

      return {
        ...state,
        [requestType]: {
          ...state[requestType][status],
          [status]: value,
        },
      };
    }

    default:
      return state;
  }
};

export default apollo;
