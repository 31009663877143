import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ThemeTooltip from '../../common/ThemeTooltip/ThemeTooltip';
import { useSidebar } from '../../../context/SidebarContext';

import { resetShipmentSidebarPreviousValue } from '../../../redux/shipments/actions';

const propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.node,
      disabled: PropTypes.bool,
      to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      isActive: PropTypes.func,
      onClick: PropTypes.func,
    }),
  )).isRequired,
};

const SidebarMenu = ({ menuItems }) => {
  const dispatch = useDispatch();
  const { isCollapsed } = useSidebar();

  const listItemsGenerator = (items) => items.map(({
    type, title, icon, disabled, to, isActive, onClick,
  }) => {
    const tooltipOptions = {
      arrow: true,
      // Only show tooltip when side bar is collapsed
      title: isCollapsed ? title : '',
      placement: 'right',
    };

    if (type === 'button') {
      return (
        <ThemeTooltip {...tooltipOptions} key={title}>
          <ListItem
            className="sidebar__nav-list__item"
            button
            onClick={() => window.open('https://support.shippio.io/hc/ja', '_blank')}
            disabled={disabled}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{title}</ListItemText>
          </ListItem>
        </ThemeTooltip>
      );
    }

    return (
      <ThemeTooltip {...tooltipOptions} key={title}>
        <ListItem
          // React router dom link component does not have disabled capability
          // https://github.com/ReactTraining/react-router/issues/1082#issuecomment-160348163
          className={`sidebar__nav-list__item${disabled ? ' sidebar__nav-list__item--is-disabled' : ''}`}
          component={NavLink}
          disabled={disabled}
          to={to}
          isActive={isActive}
          onClick={() => {
            dispatch(resetShipmentSidebarPreviousValue());
            if (onClick) {
              onClick();
            }
          }}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{title}</ListItemText>
          <div className="sidebar__active-border" />
        </ListItem>
      </ThemeTooltip>
    );
  });

  const listItemsAbove = listItemsGenerator(menuItems[0]);
  const listItemsBelow = listItemsGenerator(menuItems[1]);

  return (
    <>
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsAbove}
      </List>
      <Divider className="sidebar__divider" />
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsBelow}
      </List>
    </>
  );
};

SidebarMenu.propTypes = propTypes;

export default SidebarMenu;
