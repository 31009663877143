const SHIPMENT_DETAILS_TABS = {
  milestones: 'milestones',
  tasks: 'tasks',
  files: 'files',
  invoices: 'invoices',
  detailedInfo: 'detailedInfo',
};

const SHIPMENT_DETAILS_TABS_NSS = {
  milestones: 'milestones',
  files: 'files',
  detailedInfo: 'detailedInfo',
};

const SORT_OPTIONS = {
  import: [
    'created_at',
    'import_arrival_date',
    'import_delivery_date',
    'import_customs_clearance_date',
  ],
  export: [
    'created_at',
    'export_departure_date',
    'export_collection_date',
    'export_customs_clearance_date',
  ],
};

const SHIPMENT_STATUS_FILTER_OPTIONS = {
  all: 'all',
  active_only: 'active_only',
  cancelled_only: 'cancelled_only',
};

export {
  SHIPMENT_DETAILS_TABS,
  SHIPMENT_DETAILS_TABS_NSS,
  SORT_OPTIONS,
  SHIPMENT_STATUS_FILTER_OPTIONS,
};
