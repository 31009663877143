const CHANNEL_CONTEXT_TYPE = {
  Shipment: 'Shipment',
  QuoteLane: 'QuoteLane',
  ICP: 'IdentifiedCustomerPartner',
};

const CHANNEL_LIST_PER_QUERY = 30;

const CHANNEL_MESSAGE_TYPE = {
  file: 'file',
  text: 'text',
};

const DRAFT_CHAT_MESSAGES = 'draft-chat-messages';

const SENDER_TYPE = {
  Staff: 'Staff',
  CustomerUser: 'CustomerUser',
  CustomerPartnerUser: 'CustomerPartnerUser',
};

const PARTICIPANTS_TYPE = {
  staff_cpu: 'staff_cpu',
  staff_cu_cpu: 'staff_cu_cpu',
  staff_cu: 'staff_cu',
  cu_cu: 'cu_cu',
};

const CHAT_ASSIGNEE_SELECTIONS = {
  assignedChats: 'assignedChats',
  allChats: 'allChats',
};

const STORAGE_ASSIGNED_CHAT = 'storage-assigned-chat';

export {
  CHANNEL_CONTEXT_TYPE,
  CHANNEL_LIST_PER_QUERY,
  CHANNEL_MESSAGE_TYPE,
  CHAT_ASSIGNEE_SELECTIONS,
  DRAFT_CHAT_MESSAGES,
  SENDER_TYPE,
  STORAGE_ASSIGNED_CHAT,
  PARTICIPANTS_TYPE,
};
