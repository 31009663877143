import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { pushEvent } from '../../../redux/uiEvents/actions';

import {
  VIEW_SHIPMENT_LIST,
  VIEW_QUOTE_SIMULATION_PATTERN,
} from '../../constants/activity-log-types';
import useShipmentListActiveView from '../useShipmentListActiveView';

const useLogDirectAccessSidebarClick = (teamId) => {
  const dispatch = useDispatch();

  const tradeDirection = useSelector((state) => state.shipments.activeTradeDirection);
  const [activeView] = useShipmentListActiveView(tradeDirection, teamId);

  /**
   * Push Ui direct access event to redux depending
   * on the link clicked.
   */
  const pushUIEvent = useCallback((link) => {
    let uiEvent = null;

    switch (link) {
      case 'shipment':
        uiEvent = {
          eventTypes: [VIEW_SHIPMENT_LIST.eventType],
          trigger: VIEW_SHIPMENT_LIST.triggers.directAccess,
          options: { view: VIEW_SHIPMENT_LIST.viewOptions[activeView] },
        };
        break;

      case 'quote-tab':
        uiEvent = {
          eventTypes: [VIEW_QUOTE_SIMULATION_PATTERN.eventType],
          trigger: VIEW_QUOTE_SIMULATION_PATTERN.triggers.directAccess,
          options: {
            [VIEW_QUOTE_SIMULATION_PATTERN.options.viewLayout.key]:
              // sidebar link will never link to quote
              VIEW_QUOTE_SIMULATION_PATTERN.options.viewLayout.values.normal,
          },
        };
        break;

      default:
        break;
    }

    if (uiEvent) {
      dispatch(pushEvent(uiEvent));
    }
  }, [activeView, dispatch]);

  return [pushUIEvent];
};

export default useLogDirectAccessSidebarClick;
