import './Sidebar.scss';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ShippioLogo } from '../../../assets/icons/custom/shippio-logo-white.svg';
import { ReactComponent as ShippioLogoMini } from '../../../assets/icons/custom/shippio-logo-mini.svg';
import { ReactComponent as ShippioCircle } from '../../../assets/icons/custom/shippio-circle.svg';
import { ReactComponent as AngleRight } from '../../../assets/icons/LineAwesome/angle-right-solid.svg';
import { ReactComponent as AngleLeft } from '../../../assets/icons/LineAwesome/angle-left-solid.svg';

import { getWindowDimensions } from '../../../utils/index';
import useDebounceEffect from '../../utils/hooks/useDebounceEffect';
import { useSidebar } from '../../context/SidebarContext';

import ThemeTooltip from '../common/ThemeTooltip/ThemeTooltip';

/**
 * @param {object} t
 * @param {string} type
 * @returns object
 */
const generateToggleTooltipOptions = (t, type) => ({
  arrow: true,
  placement: 'right',
  title: t(`translation:navigation.${type}`),
  key: `sidebar__toggle__${type}`,
});

const propTypes = {
  children: PropTypes.node.isRequired,
};

const Sidebar = ({ children }) => {
  const { t } = useTranslation(['translation']);
  const primaryNavigationEl = useRef(null);
  const [isShippioCircleFits, setIsShippioCircleFits] = useState(false);
  const { setIsCollapsed, isToggleDisabled } = useSidebar();

  // To calculate height of navbar and display/hide the shippio-circle
  // Using debounce to wait for DOM mounted
  useDebounceEffect(
    () => {
      if (primaryNavigationEl.current) {
        const { height: windowHeight } = getWindowDimensions();
        const shippioLogoHeight = 85; // Fixed on scss
        const circleHeight = 156; // Fixed on scss
        const navContentsHeight = primaryNavigationEl.current.clientHeight;

        const totalHeight = shippioLogoHeight + navContentsHeight + circleHeight;

        if (windowHeight > totalHeight) {
          setIsShippioCircleFits(true);
        }
      }
    },
    [primaryNavigationEl],
    400,
  );

  return (
    <div className="sidebar" aria-label="sidebar">
      <div className="sidebar__shippio-logo">
        <ShippioLogo className="sidebar__shippio-logo--full" />
        <ShippioLogoMini className="sidebar__shippio-logo--mini" />
      </div>

      <div className={`sidebar__toggle${isToggleDisabled ? ' sidebar__toggle--is-disabled' : ''}`}>
        <ThemeTooltip {...generateToggleTooltipOptions(t, 'expand')}>
          <AngleRight className="sidebar__toggle__expand" onClick={() => setIsCollapsed(false)} />
        </ThemeTooltip>
        <ThemeTooltip {...generateToggleTooltipOptions(t, 'collapse')}>
          <AngleLeft className="sidebar__toggle__collapse" onClick={() => setIsCollapsed(true)} />
        </ThemeTooltip>
      </div>

      <div className="sidebar__menu" ref={primaryNavigationEl}>
        {/* Different menu items per each apps */}
        {children}
      </div>

      <div className={`sidebar__shippio-circle${isShippioCircleFits ? '' : ' invisible'}`}>
        <ShippioCircle />
      </div>
    </div>
  );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
