import { useDispatch, useSelector } from 'react-redux';

import { VIEW_FILTER_OPTIONS } from '../constants/shipments';

import { editShipmentListView } from '../../redux/shipments/actions';

/**
 * Custom hook for Shipment List View actions
 * @param {string} tradeDirection
 * @param {string} teamId
 */
const useShipmentListActiveView = (tradeDirection, teamId) => {
  const dispatch = useDispatch();
  // Default to first available option if there is no redux state (and not updating redux)
  // to allow elements like Shipment list to load immediately.
  const activeView = useSelector((state) => {
    // If team id and trade direction given
    if (state.shipments.view?.[teamId]?.[tradeDirection]) {
      return state.shipments.view?.[teamId]?.[tradeDirection];
    }

    // If no team id value is saved, then just grab the first view for the given trade direction
    if (VIEW_FILTER_OPTIONS?.[tradeDirection]?.[0]) {
      return VIEW_FILTER_OPTIONS[tradeDirection][0];
    }

    // Otherwise just return 'default'
    return VIEW_FILTER_OPTIONS.import[0];
  });

  const updateActiveView = (view) => {
    if (VIEW_FILTER_OPTIONS?.[tradeDirection]?.length
        && VIEW_FILTER_OPTIONS[tradeDirection].indexOf(view) >= 0) {
      dispatch(editShipmentListView(teamId, tradeDirection, view));
    }
  };

  return [activeView, updateActiveView];
};

export default useShipmentListActiveView;
