import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const ThemeTooltip = withStyles((theme) => ({
  arrow: {
    color: '#3A3E46',
  },
  tooltip: {
    backgroundColor: '#3A3E46',
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
  },
}))(Tooltip);

export default ThemeTooltip;
