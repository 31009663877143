import * as types from './types';

/**
 * @param {string} tradeDirection The trade direction to set as active i.e. 'export' or 'import'
 */
const editActiveTradeDirection = (tradeDirection) => ({
  type: types.TRADE_DIRECTION_EDIT,
  payload: { activeTradeDirection: tradeDirection },
});

/**
 * @param {string} customerTeamId
 * @param {string} tradeDirection
 * @param {string} option e.g. 'import_arrival_date'
 */
const editSelectedSort = (customerTeamId, tradeDirection, option) => ({
  type: types.SHIPMENT_SORT_EDIT,
  payload: { customerTeamId, tradeDirection, option },
});

/**
 * @param {string} searchString
 */
const editSearchShipment = (searchString) => ({
  type: types.SHIPMENT_SEARCH_EDIT,
  payload: { searchString },
});

/**
 * @param {string} view
 */
const editShipmentListView = (customerTeamId, tradeDirection, view) => ({
  type: types.SHIPMENT_VIEW_EDIT,
  payload: { customerTeamId, tradeDirection, view },
});

/**
 * @param {string} customerTeamId
 * @param {string} tradeDirection
 * @param {string} filter
 * @param {string} value
 */
const editShipmentFilterOption = (customerTeamId, tradeDirection, filter, value) => ({
  type: types.SHIPMENT_FILTER_EDIT,
  payload: {
    customerTeamId, tradeDirection, filter, value,
  },
});

/**
 * @param {bool} isCollapsed
 */
const editShipmentSidebarPreviousValue = (sidebarWasCollapsed) => ({
  type: types.SHIPMENT_SIDEBAR_PREVIOUS_EDIT,
  payload: { sidebarWasCollapsed },
});

const resetShipmentSidebarPreviousValue = () => ({
  type: types.SHIPMENT_SIDEBAR_PREVIOUS_EDIT,
  payload: { sidebarWasCollapsed: null },
});

export {
  editActiveTradeDirection,
  editSelectedSort,
  editSearchShipment,
  editShipmentListView,
  editShipmentFilterOption,
  editShipmentSidebarPreviousValue,
  resetShipmentSidebarPreviousValue,
};
