import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';

import { WALK_THROUGH_KEY } from '../../utils/constants/simulations';
import { getAuthRole, getAuthToken } from '../utils/auth/auth';
import {
  getUserTokenFromCookie, getUserTypeFromCookie, removeCookieToken, storeCookieToken,
} from '../utils/auth/cookies';
import { LOCAL_STORAGE_REMOVABLE_KEYS, USER_TYPE } from '../utils/constants/auth';

const AuthContext = React.createContext();

/**
 * Clear authorized local storage values. We can't use clear()
 * as we want to keep some values persisted over login/logout
 * e.g. i18n language
 */
const clearAuthorizedLocalStorage = () => LOCAL_STORAGE_REMOVABLE_KEYS.forEach(
  (key) => localStorage.removeItem(key),
);

// Is this the right place for this function.. not sure
function checkForToken() {
  return !!getUserTokenFromCookie();
}

const propTypes = {
  setAuthToken: PropTypes.func.isRequired,
};

function AuthProvider(props) {
  const { setAuthToken } = props;
  /**
   * Keep track of authenticated state in this provider -> could move it to Redux
   * But a lot of online articles suggest using context here, can replace
   * this logic with dispatching actions though... Up for discussion.
   */
  const [authenticated, setAuthenticated] = useState(checkForToken());
  // This is the timer reference for the interval checking for the tokens life
  const [timer, setTimer] = useState(null);
  const client = useApolloClient();

  const { logout: logoutAuth0 } = useAuth0();

  const isUserAuthenticated = () => {
    const check = checkForToken();

    if (check !== authenticated) {
      setAuthenticated(check);
    }
  };

  const isUserStaff = () => (getUserTypeFromCookie() === USER_TYPE.staff);
  const isWarehouseUser = () => (getUserTypeFromCookie() === USER_TYPE.cpuWarehouse);
  const isForwarderUser = () => (getUserTypeFromCookie() === USER_TYPE.forwarderUser);

  /**
   * Login procedures
   * - setting the auth information to the cookie
   * - resetting the Apollo Client
   * - setAuthenticated to true
   * @param {string} token user authentication token
   * @param {string} userType userType
   */
  const login = (token, userType) => {
    if (token && userType) {
      storeCookieToken(token, userType);
    }

    client.clearStore();
    setAuthToken(token);
    setAuthenticated(true);
  };

  /**
   * On logout procedure which includes removing the current token
   * from storage and resetting the Apollo Client.
   */
  const logout = async (forceRedirection = true, hardDeletion = true) => {
    client.clearStore();
    client.stop();
    setAuthToken(null);

    // if not authenticated, no need to call signOff mutation
    let signOffResult = !authenticated;

    if (timer) {
      // We don't want to have the timer running when the user is not logged in
      clearInterval(timer);
      setTimer(null);
    }

    // Sometimes authenticated is already false
    // I am re-purposing this check for authentication to trigger
    // run logout on Auth0 to delete the token
    if (authenticated) {
      setAuthenticated(false);
      // forcing redirection will be handle after sign off
      signOffResult = logoutAuth0({ returnTo: window.location.origin });
    }

    // Remove the auth token from the cookie
    removeCookieToken();
    // TODO: Having this to clean deprecated localStorage items, remove this after sometime
    localStorage.removeItem('X-Auth-Token');

    if (typeof localStorage !== 'undefined' && hardDeletion) {
      localStorage.removeItem(WALK_THROUGH_KEY);
      clearAuthorizedLocalStorage();
    }

    // Once signOff is called (if authenticated),
    // no matter it's result, execute redirection if required
    if (signOffResult && forceRedirection) {
      // Let's be hardcore
      window.location.href = '/';
    }
  };

  /**
   * When authentication has changed state to false, automatically logout
   * This is to ensure the client store is reset when the token is removed outside
   * of this context.
   */
  useEffect(() => {
    if (!authenticated) {
      logout(false);
    } else {
      const tmp = setInterval(() => {
        if (!checkForToken()) {
          logout(true, false);
        }
      }, 500);
      setTimer(tmp);
      return () => {
        clearInterval(timer);
        setTimer(null);
      };
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const availableProps = {
    authenticated,
    isUserAuthenticated,
    isUserStaff,
    isWarehouseUser,
    isForwarderUser,
    login,
    logout,
  };

  return (
    <AuthContext.Provider
      value={availableProps}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props} />
  );
}

AuthProvider.propTypes = propTypes;

const useAuth = () => useContext(AuthContext);

export {
  AuthProvider,
  useAuth,
  getAuthToken,
  getAuthRole,
};
