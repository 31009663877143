import * as types from './types';

const editNetworkRequest = (requestType, status, value) => ({
  type: types.APOLLO_NETWORK_REQUESTS_EDIT,
  payload: { requestType, status, value },
});

export {
  // eslint-disable-next-line import/prefer-default-export
  editNetworkRequest,
};
