import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-ui/core';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentLanguage } from 'shared/utils/utils';

import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../shared/context/auth';
import frontTheme from '../shared/theme/frontTheme';
import { LANGUAGE_OPTIONS } from '../shared/utils/constants/settings';
import AppFallback from './components/AppFallback/AppFallback';

const AuthenticatedAppForWarehouse = React.lazy(() => import('./AppAuthenticated'));
const AuthenticatedAppForForwarder = React.lazy(() => import('AppForwarder/AppAuthenticated'));

function App() {
  const { authenticated, isForwarderUser } = useAuth();
  const { i18n } = useTranslation();
  const {
    isAuthenticated, isLoading, error, loginWithRedirect,
  } = useAuth0();

  const getApp = () => {
    if (isLoading) {
      // Without this 'isLoading' condition, it loops and returns to the login screen.
      return true;
    }

    if (!isAuthenticated || error) {
      loginWithRedirect({ redirectUri: window.location.origin, ui_locales: getCurrentLanguage() });
      return null;
    }

    if (isForwarderUser()) {
      return (<AuthenticatedAppForForwarder />);
    }

    return (<AuthenticatedAppForWarehouse />);
  };

  useEffect(() => {
    const { language = LANGUAGE_OPTIONS.ja } = i18n;
    document.documentElement.lang = language;
  }, [i18n, i18n.language]);

  return (
    // This outer div is necessary to keep the notification popups in position
    <div>
      <ThemeProvider theme={frontTheme}>
        <ErrorBoundary>
          <Suspense fallback={<AppFallback authenticated={authenticated} />}>
            {getApp()}
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
}

export default App;
