import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY } from '../utils/constants/sidebar';

const SidebarContext = React.createContext();

const propTypes = {
  children: PropTypes.node.isRequired,
};

const SidebarProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY) === 'true',
  );

  const [isToggleDisabled, setIsToggleDisabled] = useState(false);

  const className = `fo-app${isCollapsed ? ' sidebar-collapsed' : ''}`;

  const setSidebarIsCollapsed = (status) => {
    localStorage.setItem(SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY, status);
    setIsCollapsed(status);
  };

  const sidebarValues = useMemo(() => ({
    isCollapsed,
    isToggleDisabled,
    setIsCollapsed: setSidebarIsCollapsed,
    setIsToggleDisabled,
  }), [isCollapsed, isToggleDisabled]);

  return (
    <SidebarContext.Provider value={sidebarValues}>
      <div className={className}>
        {children}
      </div>
    </SidebarContext.Provider>
  );
};

function useSidebar() {
  const context = useContext(SidebarContext);

  if (typeof context === 'undefined') {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }

  return context;
}

SidebarProvider.propTypes = propTypes;

export {
  SidebarContext,
  SidebarProvider,
  useSidebar,
};
