const CHAT_OPEN_EDIT = 'app/chat/editChatOpen';
const CHAT_LIST_OPEN_EDIT = 'app/chat/editChatListOpen';
const CHAT_CHANNELS_UNREAD_EDIT = 'app/chat/editChatChannelUnread';
const CHAT_CHANNELS_UNREAD_INCREMENT = 'app/chat/incrementChatChannelUnread';

export {
  CHAT_OPEN_EDIT,
  CHAT_LIST_OPEN_EDIT,
  CHAT_CHANNELS_UNREAD_EDIT,
  CHAT_CHANNELS_UNREAD_INCREMENT,
};
