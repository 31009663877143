/**
 * Get the current language based of the site
 * @param {i18n object} i18n
 * @param {string} fallback
 */
const getCurrentLanguage = (i18n, fallback) => i18n?.language || localStorage.getItem('i18nextLng') || fallback;

/**
 * @param {string} storageKey
 * @param {object} defaultValue
 */
const getLocalStorageObject = (storageKey, defaultValue) => {
  if (!localStorage.getItem(storageKey)) {
    return defaultValue;
  }

  // Put in a try and catch just in case the local storage item is
  // not an object.
  try {
    return JSON.parse(localStorage.getItem(storageKey));
  } catch (e) {
    return defaultValue;
  }
};

/**
 * Remove specific properties from the given object if it exists AND
 * is configurable
 * @param {object} context
 * @param {array} propertiesToRemove
 */
const removePropertiesFromObject = (context, propertiesToRemove) => {
  const clone = { ...context };

  propertiesToRemove.forEach((prop) => {
    if (clone?.[prop]) {
      delete clone[prop];
    }
  });

  return clone;
};

/**
 * Creates a new object with only the specified key and value if present, else an empty object
 * @param {string} search - url search param string
 * @param {string} key - key (property name) of param to include in returned object
 * @returns {object}
 */
const getSearchParamPart = (search, key) => {
  const params = new URLSearchParams(search);
  return params.has(key) ? { [key]: params.get(key) } : {};
};

/**
 * Clamps a number to its own value or an upper / lower limit.
 * @param {number} x - input number
 * @param {number} min - minimum accepted value
 * @param {number} max - maximum accepted value
 * @returns number;
 */
const clamp = (x, min, max) => Math.min(Math.max(x, min), max);

/**
 * Generate 32 digit hexadecimal UUID-like string, but without hyphens.
 * When in secure context (connected via https or to localhost)
 * uses the browser's built-in function intended for creating random UUIDs.
 * In other contexts, uses built-in function to generate 32 random digits;
 * @note the returned string is not a valid UUID
 * @returns string
 */
const randomID = () => {
  if (typeof window.crypto.randomUUID === 'function') {
    const hyphenatedUUID = window.crypto.randomUUID();
    return hyphenatedUUID.replace(/-/gi, '');
  }

  const uint8Array = new Uint8Array(16);
  window.crypto.getRandomValues(uint8Array);
  return [...uint8Array].map((v) => v.toString(16)).join('');
};

/*
 * parse input as integer or 0 if not parsable as integer
 * parse input as integer or return 0 if input is not parsable
 * @param {number} x
 * @returns number - parsed value or 0 if parseInt returns NaN
 */
const safeParseInt = (x) => parseInt(x, 10) || 0;

export {
  getCurrentLanguage,
  getLocalStorageObject,
  removePropertiesFromObject,
  getSearchParamPart,
  clamp,
  randomID,
  safeParseInt,
};
