import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { ReactComponent as Reload } from '../../../assets/icons/LineAwesome/reload.svg';

import ErrorContainer from '../ErrorContainer';

const ChunkLoadError = () => {
  const { t } = useTranslation(['translation']);

  const handleReload = (e) => {
    e.preventDefault();
    window.location.reload(true);
  };

  return (
    <ErrorContainer>
      <p>{t('translation:general.errorMessage.chunkError')}</p>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Reload />}
        onClick={handleReload}>
        {t('translation:general.refresh')}
      </Button>
    </ErrorContainer>
  );
};

export default ChunkLoadError;
