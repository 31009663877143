const NAME_EDIT = 'app/settings/editName';
const SET_CURRENT_USER = 'app/settings/setCurrentUser';
const TEAM_ID_EDIT = 'app/simulation/editTeamId';
const UNREAD_CHANNEL_COUNT_EDIT = 'app/settings/unreadChannelCount';
const UNREAD_ASSIGNED_CHANNEL_COUNT_EDIT = 'app/settings/unreadAssignedChannelCount';
const CHAT_ASSIGNEE_SELECTION_EDIT = 'app/settings/chatAssigneeSelection';
const LANGUAGE_EDIT = 'app/settings/editLanguage';

export {
  NAME_EDIT,
  SET_CURRENT_USER,
  TEAM_ID_EDIT,
  UNREAD_CHANNEL_COUNT_EDIT,
  UNREAD_ASSIGNED_CHANNEL_COUNT_EDIT,
  CHAT_ASSIGNEE_SELECTION_EDIT,
  LANGUAGE_EDIT,
};
