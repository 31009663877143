// This utils are necessary.
// The user role and token can be taken from getUserAuthFromCookie directly.
// But since below functions are used in several places, they are kept as is for now.

import { getUserTokenFromCookie, getUserTypeFromCookie } from './cookies';

const getAuthToken = () => getUserTokenFromCookie();
const getAuthRole = () => getUserTypeFromCookie();

export { getAuthRole, getAuthToken };
